import React, { useState } from "react";
import { Metadata } from "../../../Utilities/Metadata";
import { useLocation } from "react-router-dom";
import Airtable from "airtable";
import { Oval } from "react-loader-spinner";
import { HiPaperAirplane } from "react-icons/hi";
import { TbMessageCircle2Filled } from "react-icons/tb";

const Feedback = () => {
  const [inputtedEmail, setInputtedEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [feedback, setFeedback] = useState();
  const [validEmail, setValidEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const location = useLocation();

  const validateEmail = (input) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(input)) {
      setValidEmail(true);
      const emailInput = document.getElementById("email-input");
      emailInput.style.outline = "none";
      return;
    }
    setValidEmail(false);
    const emailInput = document.getElementById("email-input");
    emailInput.style.outline = "2px solid rgb(236, 19, 37)";
  };

  const submitFeedback = (firstName, lastName, email, type, feedback) => {
    const name = firstName + " " + lastName;
    validateEmail(email);
    if (validEmail) {
      setLoading(true);
      const base = new Airtable({
        apiKey: process.env.REACT_APP_AIRTABLE_SHARE_IDEA_TOKEN,
      }).base(process.env.REACT_APP_AIRTABLE_THE_BLOCKS_BASE);
      base("The Blocks Feedback").create(
        [
          {
            fields: {
              name: name,
              email: email,
              feedbackType: type,
              feedbackNotes: feedback,
            },
          },
        ],
        function (err) {
          if (err) {
            console.error(err);
            setLoading(false);
            return;
          }
          if (!err) {
            setInputtedEmail("");
            setFirstName("");
            setLastName("");
            setFeedback("");
            setSubmitted(true);
            setLoading(false);
            const typeSelect = document.getElementById("feedback-type");
            typeSelect.selectedIndex = 0;
          }
        }
      );
    }
    if (!validEmail) {
      const input = document.getElementById("email-input");
      input.style.outline = "2px solid rgb(187, 0, 0)";
    }
  };

  let metadata = {
    title: `The Blocks Feedback`,
    image: "",
    description:
      "Please use this form to provide feedback about our apps, resources, or website experience.",
    url: `https://theblocks.finance${location.pathname}`,
  };

  return (
    <div id="feedback" className="twelvefiftycontent">
      {Metadata(metadata)}
      <h1 className="page-title">
        <TbMessageCircle2Filled /> Feedback
      </h1>
      <div className="feedback-intro">We'd love to hear from you!</div>
      <div className="feedback-copy">
        Let's dig in to what's going on; fill out the form below to send us the
        scoop.
      </div>
      {submitted ? (
        <section className="feedback-submitted fade-in-bottom">
          <HiPaperAirplane className="fade-in-plane" />
          <h2>Feedback Submitted!</h2>
          <span>
            Thanks for sharing your thoughts with us. Your input helps improve
            the user experience.
          </span>
          <span>
            Something else on your mind?{" "}
            <span onClick={() => setSubmitted(false)}>Submit another</span>
          </span>
        </section>
      ) : (
        <section className="feedback-form">
          <input
            placeholder="First Name"
            onChange={(event) => setFirstName(event.target.value)}
            value={firstName}
          ></input>
          <input
            placeholder="Last Name"
            onChange={(event) => setLastName(event.target.value)}
            value={lastName}
          ></input>
          <input
            placeholder="Email Address"
            id="email-input"
            onChange={(event) => setInputtedEmail(event.target.value)}
            onBlur={() => validateEmail(inputtedEmail)}
            value={inputtedEmail}
          ></input>
          <select
            name="Feedback Type"
            id="feedback-type"
            required
            defaultValue="Feedback Type"
            onChange={(event) => setFeedbackType(event.target.value)}
          >
            <option className="placeholder" value="" hidden>
              Feedback Type
            </option>
            <option value="General Feedback">General Feedback</option>
            <option value="App Related">App Related</option>
            <option value="Tools Related">Tools Related</option>
            <option value="Blog Related">Blog Related</option>
          </select>
          <textarea
            placeholder="What's going on?"
            id="feedback-textbox"
            onChange={(event) => setFeedback(event.target.value)}
            value={feedback}
          ></textarea>
          <button
            className="submit-button"
            onClick={() =>
              submitFeedback(
                firstName,
                lastName,
                inputtedEmail,
                feedbackType,
                feedback
              )
            }
            disabled={
              !firstName ||
              !lastName ||
              !inputtedEmail ||
              !validEmail ||
              !feedbackType ||
              !feedback
            }
          >
            {loading ? (
              <Oval
                height={24}
                width={24}
                color="#ffffff"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#cecece"
                strokeWidth={3}
                strokeWidthSecondary={3}
                wrapperClass="mobile-spinner"
              />
            ) : (
              "Submit Feedback"
            )}
          </button>
        </section>
      )}
    </div>
  );
};

export default Feedback;
