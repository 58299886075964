import React from "react";
import { Metadata } from "../../../Utilities/Metadata";
import curlyArrow from "../../../Assets/AboutGraphic-03.svg";
import zaggArrow from "../../../Assets/AboutGraphic-02.svg";
import quote from "../../../Assets/Quote.svg";
import burst from "../../../Assets/AboutGraphic-04.svg";

const About = () => {
  let metadata = {
    title: "About The Blocks",
    url: "https://theblocks.finance/about",
    description:
      "Why build The Blocks? Explore how we approach financial education & resources.",
    image: "",
  };

  return (
    <div className="twelvefiftycontent">
      {Metadata(metadata)}
      <h1 className="about-heading">Why Build The Blocks?</h1>
      <section className="our-why">
        <p>
          Our story began with a simple yet powerful notion: that everyone
          deserves access to well-designed, easy-to-use financial tools.
          Frustrated by the lack of such tools, we set out to create a unique
          platform where financial empowerment meets user-friendly design.
        </p>
        <img
          alt="decorative curly arrow colored in Blocks blue"
          id="curly-arrow"
          src={curlyArrow}
        />
        <p>
          Our mission is to reach individuals who seek financial guidance and
          information, bridging the gap between financial complexity and
          everyday understanding. We want to ensure that sophisticated concepts
          are communicated in simple terms, enabling everyone to grasp the
          fundamentals of personal finance.
        </p>

        {/* As The Blocks grows, we aspire to
          extend our reach to philanthropic initiatives, offering communities
          access to education and resources that might otherwise be out of
          reach. */}
        <p>
          Rooted in personal experiences, our journey is driven by a commitment
          to empower individuals on their financial path. We believe that
          financial stability isn't limited to those with economic privilege;
          it's a journey that anyone can embark on. The Blocks was born from the
          understanding that wise financial choices and solid foundations can
          break the cycle of debt and living paycheck to paycheck.
        </p>
        <img
          alt="decorative zigzag arrow colored in Blocks blue"
          id="zagg-arrow"
          src={zaggArrow}
        />
        <p>
          We're not just a company; we're your financial partner. We hold design
          to the highest standard. We make educational resources freely
          available, and nurture a community dedicated to supporting one
          another, no matter where they are in their financial journey.
          Challenges motivate us, and we firmly believe that{" "}
          <span style={{ fontStyle: "oblique" }}>
            "It wouldn't be fun if it was easy."
          </span>
        </p>
        <p>
          From apps to blog posts and a wealth of digital and physical
          resources, we're committed to supporting each step of your financial
          growth journey. Regardless of your starting point, we aim to provide
          you with the right tools to make informed decisions for your unique
          situation.
        </p>
        <img id="burst" src={burst} />
        <p>
          We hope you'll explore our resources and provide us with your
          feedback, we're always eager to hear about how we can adapt and
          improve.
        </p>
      </section>
      <section className="quote-wrapper">
        <img
          alt="The Blocks' guiding quote, 'It wouldn't be fun if it was easy.'"
          src={quote}
        />
      </section>
    </div>
  );
};

export default About;
