import "./App.scss";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from "react-router-dom";
import Landing from "./Components/Landing/Landing";
import Resources from "./Components/Resources/Resources";
import Footer from "./Components/Global/Footer/Footer";
import PageNotFound from "./Components/Global/Support/PageNotFound";
import Feedback from "./Components/Global/Support/Feedback";
import TermsOfUse from "./Components/Global/Support/TermsOfUse";
import PrivacyPolicy from "./Components/Global/Support/PrivacyPolicy";
import About from "./Components/Global/Support/About";
import Roadmap from "./Components/Resources/Roadmap";
import Contact from "./Components/Global/Support/Contact";
import Header from "./Components/Global/Header/Header";
import Announcements from "./Components/Announcements/Announcements";
import { Post } from "./Components/Resources/Posts/Post";
import { Topic } from "./Components/Resources/Topic";
import AppCategory from "./Components/Resources/Apps/AppCategory";

const ScrollToTop = (props) => {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState();
  const match = useMatch("/resources/:topic/:post");
  useEffect(() => {
    if (!previousPath) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      setPreviousPath(location.pathname);
    } else {
      if (
        location.pathname.includes("/resources") &&
        !match &&
        previousPath.includes("/resources")
      ) {
        return;
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        setPreviousPath(location.pathname);
      }
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  return <>{props.children}</>;
};

const watchForHover = () => {
  // lastTouchTime is used for ignoring emulated mousemove events
  let lastTouchTime = 0;

  function enableHover() {
    if (new Date() - lastTouchTime < 500) return;
    document.body.classList.add("hasHover");
  }

  function disableHover() {
    document.body.classList.remove("hasHover");
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date();
  }

  document.addEventListener("touchstart", updateLastTouchTime, true);
  document.addEventListener("touchstart", disableHover, true);
  document.addEventListener("mousemove", enableHover, true);

  enableHover();
};

watchForHover();

ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);

function App() {
  const postsUrl =
    "https://public-api.wordpress.com/rest/v1.1/sites/blocksfinance.wordpress.com/posts/?category=the-blocks/posts";

  const [loadingPosts, setLoadingPosts] = useState(true);
  const [recentPosts, setRecentPosts] = useState();
  const [legalDocs, setLegalDocs] = useState();
  const [search, setSearch] = useState(false);
  const [terms, setTerms] = useState([]);
  const [loadingTerms, setLoadingTerms] = useState(false);
  const [visited, setVisited] = useState(false);

  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  useEffect(() => {
    fetch(postsUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        let legalDocs = [];
        let posts = [];
        data.posts.map((post) => {
          if (post.categories.hasOwnProperty("Legal")) {
            legalDocs.push(post);
          } else {
            posts.push(post);
          }
        });
        setRecentPosts(posts);
        setLegalDocs(legalDocs);
        setLoadingPosts(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingPosts(false);
      });
  }, [iOSSafari]);

  return (
    <Router>
      <ScrollToTop>
        <section className="blocks-header">
          <Header />
        </section>
        <section className="page-content">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/resources" element={<Resources />}>
              {/* Apps Get Dedicated Routes */}
              <Route
                path="/resources/calculators"
                element={
                  <AppCategory title="Calculators" category="calculator" />
                }
              />
              <Route
                path="/resources/hsa"
                element={<AppCategory title="HSA" category="hsa" />}
              />

              <Route path="/resources/:topic" element={<Topic />} />
            </Route>
            <Route
              path="/resources/:topic/:post"
              element={<Post recentPosts={recentPosts} />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route
              path="/announcements/:announcement"
              element={<Announcements />}
            />
            <Route
              path="/roadmap"
              element={<Roadmap iOSSafari={iOSSafari} />}
            />
            <Route path="/feedback" element={<Feedback />} />
            <Route
              path="/terms-of-use"
              element={<TermsOfUse terms={terms} loading={loadingTerms} />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </section>
        <section className="blocks-footer">
          <Footer />
        </section>
      </ScrollToTop>
    </Router>
  );
}

export default App;
