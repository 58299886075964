import React, { useEffect, useState } from "react";
import { HiMenuAlt4 } from "react-icons/hi";
import { TbX, TbCardsFilled } from "react-icons/tb";
import { PiScribbleLoopBold, PiMapPinFill } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();

  useEffect(() => {
    updateWidth();
  }, []);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);
  };

  window.addEventListener("resize", updateWidth);

  const [showMenu, setShowMenu] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const NavigationItems = () => {
    return (
      <nav>
        <Link
          to="/resources"
          onClick={() => (!isDesktop ? toggleMenu() : null)}
          className={location.pathname.includes("/resources") ? "active" : null}
        >
          {!isDesktop && <TbCardsFilled />} resources
        </Link>
        <Link
          to="/about"
          onClick={() => (!isDesktop ? toggleMenu() : null)}
          className={location.pathname === "/about" ? "active" : null}
        >
          {!isDesktop && <PiScribbleLoopBold />} about
        </Link>
        <Link
          to="/roadmap"
          onClick={() => (!isDesktop ? toggleMenu() : null)}
          className={location.pathname.includes("/roadmap") ? "active" : null}
        >
          {!isDesktop && <PiMapPinFill />} roadmap
        </Link>
      </nav>
    );
  };

  return (
    <div>
      <div className="desktop-menu">
        <NavigationItems />
      </div>
      <div className="mobile-menu">
        <div className="mobile-header">
          <HiMenuAlt4 className="menu-toggle" onClick={toggleMenu} />
        </div>
        <div
          className="mobile-overlay"
          onClick={toggleMenu}
          id={showMenu ? "" : "overlay-hidden"}
        ></div>
        <div className="menu-container" id={showMenu ? "" : "container-hidden"}>
          <div className="close">
            <TbX onClick={toggleMenu} />
          </div>
          <NavigationItems />
        </div>
      </div>
    </div>
  );
};

export { Navigation };
