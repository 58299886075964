import React from "react";
import { Navigation } from "../Navigation/Navigation";
import { Link } from "react-router-dom";
import BlocksDark from "../../../Assets/TheBlocksDark.svg";

const Header = () => {
  return (
    <section className="header-wrapper">
      <Link to="/" className="blocks-brand">
        <img alt="The Blocks logo" src={BlocksDark} />
        <h2>The Blocks</h2>
      </Link>
      <Navigation />
    </section>
  );
};

export default Header;
