import React, { useState } from "react";
import Airtable from "airtable";
import {
  BsFillArrowRightCircleFill,
  BsFillCheckCircleFill,
} from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const Subscription = () => {
  const [inputtedEmail, setInputtedEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  const handleChange = (event) => {
    setInputtedEmail(event.target.value);
    if (validEmail) {
      const input = document.getElementById("email-input");
      input.style.outline = "none";
    }
  };

  const validateEmail = (input) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(input)) {
      setValidEmail(true);
      return;
    }
    setValidEmail(false);
  };

  const addSubscriber = (email) => {
    validateEmail(email);
    if (validEmail) {
      setLoading(true);
      const base = new Airtable({
        apiKey: process.env.REACT_APP_AIRTABLE_HOMEPAGE_CAPTURE_TOKEN,
      }).base(process.env.REACT_APP_AIRTABLE_THE_BLOCKS_BASE);
      base("Home Page Subscribers").create(
        [
          {
            fields: {
              Email: email,
            },
          },
        ],
        function (err, records) {
          if (err) {
            console.error(err);
            return;
          }
          if (!err) {
            setInputtedEmail("");
            setSignedUp(true);
            setLoading(false);
          }
          records.forEach(function (record) {});
        }
      );
    }
    if (!validEmail) {
      const input = document.getElementById("email-input");
      input.style.outline = "2px solid rgb(187, 0, 0)";
    }
  };

  return (
    <>
      <div className="contact-block twelvefiftycontent">
        <h1>Keep me up to date on new apps & resources</h1>
        {signedUp ? (
          <div className="sign-up-success fade-in-fwd">
            <BsFillCheckCircleFill className="scale-in-center" /> Success.
            You're In!
          </div>
        ) : (
          <div className="input-wrapper">
            <input
              placeholder="howdy@email.com"
              onChange={handleChange}
              value={inputtedEmail}
              id="email-input"
            ></input>
            {loading ? (
              <Oval
                height={28}
                width={28}
                color="#407DFF"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#cecece"
                strokeWidth={2}
                strokeWidthSecondary={2}
                wrapperClass="mobile-spinner"
              />
            ) : (
              <BsFillArrowRightCircleFill
                className="mobile-arrow"
                onClick={() => addSubscriber(inputtedEmail)}
              />
            )}
            <button onClick={() => addSubscriber(inputtedEmail)}>
              {loading ? (
                <Oval
                  height={28}
                  width={28}
                  color="#ffffff"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#cecece"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        )}
        {validEmail ? (
          ""
        ) : (
          <div className="support-text" id="error">
            Please enter a valid email address.
          </div>
        )}
        {signedUp ? (
          ""
        ) : (
          <span className="support-text">No worries, unsubscribe anytime.</span>
        )}
      </div>
    </>
  );
};

export default Subscription;
