import React from 'react';
import { Link } from 'react-router-dom';
import { HiGlobeAlt } from 'react-icons/hi';

const PageNotFound = () => {
  return (
    <div className='page-content page-not-found'>
      
      <div className='error-num'>404</div>
      <div className='error-text'>Yikes, page not found. Let's try again or <Link to='/' className='error-link'>go home</Link>.</div>
      <div className='error-icon'><HiGlobeAlt /></div>
    </div>
  )
}

export default PageNotFound