import React from "react";
import { Helmet } from "react-helmet";

const Metadata = (payload) => {
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{payload?.title}</title>
      <meta name="title" content={payload?.title} />
      <meta name="description" content={payload?.description} />
      {payload?.isPost ? <link rel="canonical" href={payload?.url} /> : null}

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={payload?.url} />
      <meta property="og:title" content={payload?.title} />
      <meta property="og:description" content={payload?.description} />
      <meta property="og:image" content={payload?.image} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={payload?.url} />
      <meta property="twitter:title" content={payload?.title} />
      <meta property="twitter:description" content={payload?.description} />
      <meta property="twitter:image" content={payload?.image} />
    </Helmet>
  );
};

export { Metadata };
