import React, { useState, useEffect } from "react";
import { Metadata } from "../../Utilities/Metadata";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbArrowNarrowRight, TbArrowBackUp } from "react-icons/tb";

const Announcements = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [announcements, setAnnouncements] = useState();
  const [singleAnnouncement, setSingleAnnouncement] = useState();
  const [noAnnouncementsFound, setNoAnnouncementsFound] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const dataUrl = `https://public-api.wordpress.com/rest/v1.1/sites/blocksfinance.wordpress.com/posts/?category=the-blocks/announcements`;

  let metadataAnnouncements = {
    title: `The Blocks Announcements`,
    image: "",
    description: "Check out our recent updates to products & resources.",
    url: `https://theblocks.finance${location.pathname}`,
  };

  let metadataSingleAnnouncement = {
    title: singleAnnouncement?.title,
    image: singleAnnouncement?.post_thumbnail?.URL,
    description: singleAnnouncement?.excerpt,
    url: `https://theblocks.finance${location.pathname}`,
    isPost: true,
  };

  const fetchPosts = async () => {
    await fetch(dataUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log(data.posts);
        if (data.found > 0) {
          setNoAnnouncementsFound(false);
          setAnnouncements(data.posts);
        } else {
          setNoAnnouncementsFound(true);
          setAnnouncements([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    console.log(announcements);
    setIsLoading(true);
    if (location.state && location.pathname.slice(14).length > 1) {
      setSingleAnnouncement(location.state.announcement);
      setIsLoading(false);
    }
    if (location.pathname.slice(14).length < 2) {
      setSingleAnnouncement();
      setIsLoading(false);
    }
    if (!announcements) {
      fetchPosts();
    }
    let match = [];
    if (!location.state && announcements) {
      let slug = location.pathname.slice(15);
      announcements.map((post) => {
        if (slug === post.slug) {
          setSingleAnnouncement(post);
          match.push(post);
          setIsLoading(false);
          return;
        }
      });
      if (
        !singleAnnouncement &&
        !location.state &&
        announcements &&
        match.length < 1
      ) {
        setNoAnnouncementsFound(true);
        setSingleAnnouncement(null);
        setIsLoading(false);
      }
    }
    console.log(singleAnnouncement);
  }, [announcements, location]);

  const formatDate = (postDate) => {
    const formattedDate = new Date(postDate).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  };

  const handleTag = (tag) => {
    let result = Object.keys(tag).map((key) => [key]);
    console.log(result);
    return result[0];
  };

  return (
    <div className="twelvefiftycontent" id="announcements">
      {Metadata(metadataAnnouncements)}
      {!isLoading && singleAnnouncement && (
        <div className="single-announcement-page">
          {!location.state?.announcement.length > 0 && (
            <a
              className="back-to-announcements"
              onClick={() => navigate("/announcements", { replace: true })}
            >
              <TbArrowBackUp /> Back to all annoucements
            </a>
          )}
          {Metadata(metadataSingleAnnouncement)}
          <section className="single-announcement-content">
            <img
              alt="announcement content featured photo"
              src={singleAnnouncement?.post_thumbnail?.URL}
            />
            <span
              className={
                handleTag(singleAnnouncement.tags).includes("SIGN-UPS OPEN")
                  ? "tag sign-up"
                  : "tag"
              }
            >
              {handleTag(singleAnnouncement.tags)}
            </span>
            <h1>{singleAnnouncement?.title}</h1>
            <h2>{formatDate(singleAnnouncement?.date)}</h2>
            <p
              dangerouslySetInnerHTML={{ __html: singleAnnouncement?.content }}
            />
          </section>
        </div>
      )}

      {announcements && !singleAnnouncement && (
        <div>
          <h1 className="page-title">Announcements</h1>
          {noAnnouncementsFound && announcements.length < 1 && (
            <div className="no-found-text">
              No announcements were found. Please check again later.
            </div>
          )}
          {announcements && !isLoading ? (
            <section className="announcements-container">
              {announcements.map((announcement) => {
                return (
                  <div
                    key={announcement.slug}
                    className="announcement-card"
                    onClick={() =>
                      navigate(`/announcements/${announcement.slug}`)
                    }
                  >
                    <span
                      className={
                        handleTag(announcement.tags).includes("SIGN-UPS OPEN")
                          ? "tag sign-up"
                          : "tag"
                      }
                    >
                      {handleTag(announcement.tags)}
                    </span>
                    <h2>{announcement.title}</h2>
                    <span className="date">
                      {formatDate(announcement.date)}
                    </span>
                    <p
                      dangerouslySetInnerHTML={{ __html: announcement.excerpt }}
                    />
                    <span className="read-more">
                      Read More <TbArrowNarrowRight />
                    </span>
                  </div>
                );
              })}
            </section>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Announcements;
