import React, { useEffect, useState } from 'react';

const Badge = ({badge}) => {
    
    const [ badgeText, setBadgeText ] = useState();
    const [ badgeClass, setBadgeClass ] = useState();

    useEffect(() => {
        setBadge();
    }, [badge]);

    const setBadge = () => {
        switch (badge) {
            case "new":
                setBadgeText("New");
                setBadgeClass("new");
                break;
            case "coming-soon":
                setBadgeText("Coming Soon");
                setBadgeClass("coming-soon");
                break;
            case "feature":
                setBadgeText("Feature");
                setBadgeClass("feature");
                break;
            default:
                setBadgeText("Oops");
                setBadgeClass("");
                break;
        }
    }

    return (
        <span id='blocks-badge' className={badgeClass}>{badgeText}</span>
    )
}

export { Badge }