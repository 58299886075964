import React, { useState, useEffect } from "react";
import { Metadata } from "../../Utilities/Metadata";
import AppGallery from "./AppGallery";
import Subscription from "../Global/Support/Subscription";
import { useNavigate } from "react-router-dom";
import ShoeboxFeature from "../../Assets/The-Blocks-Feature-Image.webp";
import ShoeboxFeatureMobile from "../../Assets/The-Blocks-Feature-Image-Mobile.webp";
import { TbArrowRight } from "react-icons/tb";

const Landing = () => {
  useEffect(() => {
    updateWidth();
  }, []);

  const navigate = useNavigate();

  const updateWidth = () => {
    setIsMidWidth(!(window.innerWidth > 749 || window.innerWidth < 550));
    setIsMobile(window.innerWidth < 751);
  };

  window.addEventListener("resize", updateWidth);

  const [isMidWidth, setIsMidWidth] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  let metadata = {
    title: "The Blocks",
    description: "We help build a solid foundation for your financial future.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/shoebox-dev-app.appspot.com/o/global-items%2Fresources%2Fgeneral%20assets%2FBlocksBanner2.jpg?alt=media&token=c98f15a2-903f-4fa9-ab6a-c156736a2a6a",
    url: "https://theblocks.finance",
  };

  return (
    <div id="home">
      {Metadata(metadata)}
      <section className="home-fold">
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient
              id="a"
              gradientUnits="objectBoundingBox"
              x1="0"
              y1="0"
              x2="1"
              y2="1"
            >
              <stop offset="0" stop-color="#9cc9ff">
                <animate
                  attributeName="stop-color"
                  values="#9cc9ff;white;#cae1fe;white;#cae1fe;#9cc9ff;#9cc9ff;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset=".5" stop-color="#cae1fe">
                <animate
                  attributeName="stop-color"
                  values="#cae1fe;white;#cae1fe;#9cc9ff;#cae1fe;white;#cae1fe;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset="1" stop-color="#9cc9ff">
                <animate
                  attributeName="stop-color"
                  values="white;#cae1fe;white;#cae1fe;white;#cae1fe;white;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <animateTransform
                attributeName="gradientTransform"
                type="rotate"
                from="0 .5 .5"
                to="360 .5 .5"
                dur="20s"
                repeatCount="indefinite"
              />
            </linearGradient>
            <linearGradient
              id="b"
              gradientUnits="objectBoundingBox"
              x1="0"
              y1="1"
              x2="1"
              y2="1"
            >
              <stop offset="0" stop-color="#9cc9ff">
                <animate
                  attributeName="stop-color"
                  values="#9cc9ff;white;#cae1fe;white;#cae1fe;#9cc9ff;#9cc9ff;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset="1" stop-color="#cae1fe" stop-opacity="0">
                <animate
                  attributeName="stop-color"
                  values="#cae1fe;white;#cae1fe;#9cc9ff;#cae1fe;white;#cae1fe;"
                  dur="25s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <animateTransform
                attributeName="gradientTransform"
                type="rotate"
                values="360 .5 .5;0 .5 .5"
                class="ignore"
                dur="10s"
                repeatCount="indefinite"
              />
            </linearGradient>
          </defs>
          <rect fill="url(#a)" width="100%" height="100%" />
          <rect fill="url(#b)" width="100%" height="100%" />
        </svg>
        <div className="fold-content">
          <div className="title-tagline">
            <h1>
              We help build a solid<br></br> foundation for your<br></br>{" "}
              financial future
            </h1>
          </div>
          <div className="fold-navigation">
            <button onClick={() => navigate("/resources")}>
              {isMidWidth ? "Resources" : "See Resources"}
            </button>
            <button onClick={() => navigate("/about")}>
              {isMidWidth ? "About Us" : "About The Blocks"}
            </button>
            <button onClick={() => navigate("/roadmap")}>
              See What's Next
            </button>
          </div>
        </div>
      </section>
      <section className="blocks-intro" id="the-blocks">
        <img
          alt="featured photo of an iPhone with the Shoebox UI"
          src={isMobile ? ShoeboxFeatureMobile : ShoeboxFeature}
        />
        <div className="feature-copy">
          <span>Introducing</span>
          <h3>Shoebox</h3>
          <p>Track, Manage, and Maximize Your HSA Contributions.</p>
          <a href="https://shoebox.finance/#notify-me" target="_blank">
            <sl-button class="get-in-line-btn">
              Get In Line <TbArrowRight />
            </sl-button>
          </a>
        </div>
      </section>
      <section className="email-capture">
        <Subscription />
      </section>
      <section className="resource-showcase">
        <AppGallery />
      </section>
    </div>
  );
};

export default Landing;
