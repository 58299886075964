import React from "react";
import { Badge } from "../../Global/Badges/Badge";

const AppCard = ({ appIcon, title, badge, description, actions }) => {
  return (
    <div className="app-card">
      <img
        alt="app icon badge"
        src={appIcon}
        className={title === "Shoebox" ? "app-icon white" : "app-icon"}
      />
      <div className="app-body">
        <span className="app-title">
          <h3>{title}</h3>
          {badge === "" ? null : <Badge badge={badge} />}
        </span>
        <span className="app-description">{description}</span>
        <span className="app-actions">
          {actions.map((action, index) => {
            return (
              <span key={index}>
                <a
                  href={action.link}
                  target={action.external ? "_blank" : null}
                >
                  {action.title}
                </a>
                {index + 1 === actions.length ? null : (
                  <div className="vertical-rule"></div>
                )}
              </span>
            );
          })}
        </span>
      </div>
    </div>
  );
};

export { AppCard };
