import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBriefcaseMedical, FaFolderOpen, FaCouch } from "react-icons/fa";
import { IoCalculator } from "react-icons/io5";
import {
  BsFillPiggyBankFill,
  BsCreditCard,
  BsFileEarmarkSpreadsheetFill,
} from "react-icons/bs";
import { TbCircleArrowLeftFilled, TbAppsFilled } from "react-icons/tb";
import { PiPushPinFill } from "react-icons/pi";
import * as FaIcon from "react-icons/fa";
import * as BsIcon from "react-icons/bs";
import * as TbIcon from "react-icons/tb";
import * as PiIcon from "react-icons/pi";
import * as IoIcon from "react-icons/io5";
import { Badge } from "../../Global/Badges/Badge";

const Sidebar = ({ expandedSidebar, setExpandedSidebar, availableTopics }) => {
  useEffect(() => {
    updateWidth();
  }, []);

  const [isDesktop, setIsDesktop] = useState(true);
  const [firstExpand, setFirstExpand] = useState(false);

  const location = useLocation();

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 750);
    if (window.innerWidth > 750) {
      setExpandedSidebar(true);
      setFirstExpand(false);
    }
    if (window.innerWidth < 751 && !firstExpand) {
      setExpandedSidebar(false);
    }
  };

  const Icon = ({ iconName }) => {
    let icon;

    if (iconName.slice(0, 2) === "Io") {
      icon = React.createElement(IoIcon[iconName]);
    } else if (iconName.slice(0, 2) === "Tb") {
      icon = React.createElement(TbIcon[iconName]);
    } else if (iconName.slice(0, 2) === "Fa") {
      icon = React.createElement(FaIcon[iconName]);
    } else if (iconName.slice(0, 2) === "Bs") {
      icon = React.createElement(BsIcon[iconName]);
    } else if (iconName.slice(0, 2) === "Pi") {
      icon = React.createElement(BsIcon[iconName]);
    }

    return icon;
  };

  window.addEventListener("resize", updateWidth);

  const toggleSidebar = () => {
    setExpandedSidebar(!expandedSidebar);
    setFirstExpand(true);
  };

  return (
    <section
      className={
        expandedSidebar ? "resource-sidebar expanded" : "resource-sidebar"
      }
    >
      <div className="apps">
        <h4>{expandedSidebar ? "Apps" : <TbAppsFilled />}</h4>
        <div className="nav-item-with-badge">
          <Link
            to="/resources/hsa"
            className={location.pathname === "/resources/hsa" ? "active" : ""}
            onClick={expandedSidebar && !isDesktop ? toggleSidebar : null}
          >
            <FaBriefcaseMedical className="icon-adjust hsa-sidebar-icon" />{" "}
            {expandedSidebar ? "HSA" : null}
          </Link>
          {expandedSidebar ? <Badge badge="new" /> : null}
        </div>
        <Link
          to="/resources/calculators"
          className={
            location.pathname === "/resources/calculators" ? "active" : ""
          }
          onClick={expandedSidebar && !isDesktop ? toggleSidebar : null}
        >
          <IoCalculator className="calculators-sidebar-icon" />
          {expandedSidebar ? "Calculators" : null}
        </Link>
      </div>
      <hr></hr>
      <div className="posts-n-resources">
        <h4>
          {expandedSidebar ? (
            "Posts & Resources"
          ) : (
            <PiPushPinFill id="post-pin" />
          )}
        </h4>
        {availableTopics.map((topic, index) => {
          return (
            <Link
              key={index}
              className={location.pathname === topic.route ? "active" : ""}
              to={topic.title.toLowerCase().split(" ").join("-")}
              onClick={expandedSidebar && !isDesktop ? toggleSidebar : null}
            >
              <Icon iconName={topic.icon} />
              {expandedSidebar ? topic.title : null}
            </Link>
          );
        })}
      </div>
      {isDesktop ? null : (
        <div className="sidebar-mobile-toggle" onClick={() => toggleSidebar()}>
          <TbCircleArrowLeftFilled /> {expandedSidebar ? "Collapse" : null}
        </div>
      )}
    </section>
  );
};

export default Sidebar;
